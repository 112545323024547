import nl from "~/lang/nl.json";
import be from "~/lang/nl-BE.json";
import fr from "~/lang/fr.json";
/*
 * We are (unfortunately) using nl-NL as code for the dutch language in allefolders,
 * and just 'nl' as code for flemish in promopromo, because promopromo uses the /nl prefix.
 * This also reflects in the defineI18nroutes compiler macro found on the pages components.
 */
export default defineI18nConfig(() => ({
  fallbackLocale: "nl-NL",
  defaultLocale: "nl-NL",
  locales: ["nl-NL", "nl", "fr"],
  legacy: false,
  messages: {
    "nl-NL": nl,
    nl: be,
    fr: fr,
  },
}));
